import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { Redirection, AppConfig, PageComponent } from '../../common';

@Component({
    selector: 'tyt-redirection',
    templateUrl: './redirection.component.html',
    styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent extends PageComponent implements OnInit {
    init() {
        this.route.data
            .subscribe(data => this.redirection.go(data.url.replace(/\{accounts\}/, environment.urls.accounts)));
    }
}

export function externalRedirect(path: string, url: string): Route {
    return { path, component: RedirectionComponent, data: { url }};
}